/* @jsx UiSchema.createElement */
import {UiSchema} from '@rushplay/forms'

/**
 * UISchemas for Payment providers
 * This is where you need to add the form visualisations
 * for each new payment-method
 */

// -----------------------------------------------------------------------
// Reusable UISchema components with basic functionality

/* eslint-disable react/prop-types */
function NewAccountOnly(props) {
  return (
    <UiSchema.Fragment
      effects={[
        UiSchema.effects.hide(),
        UiSchema.effects.show({
          properties: {
            accountId: {
              const: '',
            },
          },
        }),
      ]}
    >
      {props.children}
    </UiSchema.Fragment>
  )
}

function PaymentMethod(props) {
  return (
    <UiSchema.Fragment
      baseScope={`#/definitions/${props.paymentMethodId}/`}
      effects={[
        UiSchema.effects.hide(),
        UiSchema.effects.show({
          properties: {
            provider: {
              const: props.paymentMethodId,
            },
          },
          required: ['provider'],
        }),
      ]}
    >
      {props.children}
    </UiSchema.Fragment>
  )
}
/* eslint-enable react/prop-types */

// -----------------------------------------------------------------------
// Provider specific UISchema setups

function PiqAstropaycardGoDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaycardGoDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqAstropaycardNbDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaycardNbDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqAstropaycardPhDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaycardPhDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqAstropaycardTmDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaycardTmDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqAstropaycardUiDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaycardUiDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankBlDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankBlDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankIxDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankIxDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankJcDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankJcDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankBtvoucherDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankBtvoucherDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankBtvoucherAtmDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankBtvoucherAtmDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankPpbtvoucherDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankPpbtvoucherDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankBriteDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankBriteDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankNbDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankNbDeposit">
      <UiSchema.Control scope="properties/service" />
      <NewAccountOnly>
        <UiSchema.Control scope="properties/nationalId" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankUiDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankUiDeposit">
      <UiSchema.Control scope="properties/service" />
      <NewAccountOnly>
        <UiSchema.Control scope="properties/nationalId" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankDirecta24Deposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankDirecta24Deposit">
      <UiSchema.Component id="directa24PaymentMethods" />
    </PaymentMethod>
  )
}

function PiqBankInovapayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankInovapayDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankPixDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankPixDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankNetBankingDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankNetBankingDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/bankName" />
    </PaymentMethod>
  )
}

function PiqBankRedeemVoucherDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankRedeemVoucherDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankUpiDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankUpiDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/bankName" />
    </PaymentMethod>
  )
}

function PiqBankVoltDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankVoltDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
    </PaymentMethod>
  )
}

function PiqBankVoltWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankVoltWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankWalletDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Component id="paymeroWalletCodeSelect" />
    </PaymentMethod>
  )
}

function PiqInovapaybankWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqInovapaybankWithdrawal">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
      <UiSchema.Control scope="properties/branchCode" />
      <UiSchema.Control scope="properties/accountNumber" />
      <UiSchema.Control scope="properties/accountType" />
    </PaymentMethod>
  )
}

function PiqInovapaybankPixWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqInovapaybankPixWithdrawal">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/nationalId" />
      <UiSchema.Control scope="properties/pixKey" />
    </PaymentMethod>
  )
}

function PiqInovapaywalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqInovapaywalletDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/userLogin" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/userSecureId" />
    </PaymentMethod>
  )
}

function PiqInovapaywalletWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqInovapaywalletWithdrawal">
      <UiSchema.Control scope="properties/userLogin" />
      <UiSchema.Control scope="properties/userSecureId" />
    </PaymentMethod>
  )
}

function PiqGate2waywalletPaytmWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqGate2waywalletPaytmWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletNumber" />
        <UiSchema.Control scope="properties/walletName" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankInteracDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankInteracDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankParamountDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankParamountDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankibanDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/iban" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankibanOnlineueberweisenDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanOnlineueberweisenDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqCashlibDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCashlibDeposit">
      <UiSchema.Control scope="properties/voucherNumber" />
    </PaymentMethod>
  )
}

function PiqPproDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqPproDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqPproIdealDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqPproIdealDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqAstropaybankWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaybankWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/bankCode" />
        <UiSchema.Control scope="properties/nationalId" />
        <UiSchema.Control scope="properties/bankBranch" />
        <UiSchema.Control scope="properties/bankAccount" />
        <UiSchema.Control scope="properties/accountType" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqAstropaybankD24indiaWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaybankD24indiaWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/nationalId" />
        <UiSchema.Control scope="properties/bankBranch" />
        <UiSchema.Control scope="properties/bankAccount" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqAstropaybankPixWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqAstropaybankPixWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/nationalId" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankdomesticWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankdomesticWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/beneficiaryName" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankibanWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/iban" />
        <UiSchema.Control scope="properties/bic" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankibanBriteWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanBriteWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/iban" />
        <UiSchema.Control scope="properties/bic" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankibanEbpWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanEbpWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/iban" />
        <UiSchema.Control scope="properties/bic" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankibanNodapayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankibanNodapayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/iban" />
        <UiSchema.Control scope="properties/bic" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankintlWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankintlWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bic" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankBriteWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankBriteWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankintlInpayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankintlInpayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/beneficiaryStreet" />
        <UiSchema.Control scope="properties/beneficiaryZip" />
        <UiSchema.Control scope="properties/beneficiaryCity" />
        <UiSchema.Control scope="properties/beneficiaryState" />
        <UiSchema.Control scope="properties/beneficiaryCountry" />
        <UiSchema.Control scope="properties/bic" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/branchName" />
        <UiSchema.Control scope="properties/branchAddress" />
        <UiSchema.Control scope="properties/branchCode" />
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBankintlNzdWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankintlNzdWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/bic" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/branchAddress" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBankintlNetbankingWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBankintlNetbankingWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/beneficiaryStreet" />
        <UiSchema.Control scope="properties/beneficiaryZip" />
        <UiSchema.Control scope="properties/beneficiaryCity" />
        <UiSchema.Control scope="properties/beneficiaryState" />
        <UiSchema.Control scope="properties/beneficiaryCountry" />
        <UiSchema.Control scope="properties/bic" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/branchName" />
        <UiSchema.Control scope="properties/branchAddress" />
        <UiSchema.Control scope="properties/branchCode" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBestpaytransferWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBestpaytransferWithdrawal">
      <UiSchema.Control scope="properties/accountNumber" />
      <UiSchema.Control scope="properties/branchCode" />
    </PaymentMethod>
  )
}

function PiqPaymerobankWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPaymerobankWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Component id="paymeroWithdrawalsCodeSelect" />
        <UiSchema.Control scope="properties/ifscCode" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBanklocalWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBanklocalInterkassaWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalInterkassaWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/accountNumber"
          inputMode="numeric"
        />
        <UiSchema.Control scope="properties/clearingNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalInpayjpWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalInpayjpWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalSumopayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalSumopayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqBanklocalPagavaWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalPagavaWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalUpiWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalUpiWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalBtbWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalBtbWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalImpsWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalImpsWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalVipwithdrawalJpWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalVipwithdrawalJpWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/branchName" />
        <UiSchema.Control scope="properties/localBeneficiaryName" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqCreditcardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCreditcardDeposit">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/cardHolder"
          forwardProps={{
            autoComplete: 'cc-name',
          }}
        />
        <UiSchema.Control
          scope="properties/encCreditcardNumber"
          forwardProps={{
            autoComplete: 'cc-number',
            inputMode: 'numeric',
            maxLength: '23',
          }}
        />
      </NewAccountOnly>
      <UiSchema.Box
        display="grid"
        gridGap="inherit"
        gridTemplateColumns="repeat(auto-fit, minmax(70px, 1fr))"
      >
        <NewAccountOnly>
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-month',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryMonth"
          />
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-year',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryYear"
          />
        </NewAccountOnly>
        <UiSchema.Control
          scope="properties/encCvv"
          forwardProps={{
            autoComplete: 'cc-csc',
            inputMode: 'numeric',
            maxLength: '4',
          }}
        />
      </UiSchema.Box>
    </PaymentMethod>
  )
}

function PiqMifinityUnionpayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqMifinityUnionpayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqMifinityKlarnaDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqMifinityKlarnaDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqCreditcardMastercardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCreditcardMastercardDeposit">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/cardHolder"
          forwardProps={{
            autoComplete: 'cc-name',
          }}
        />
        <UiSchema.Control
          scope="properties/encCreditcardNumber"
          forwardProps={{
            autoComplete: 'cc-number',
            inputMode: 'numeric',
            maxLength: '23',
          }}
        />
      </NewAccountOnly>
      <UiSchema.Box
        display="grid"
        gridGap="inherit"
        gridTemplateColumns="repeat(auto-fit, minmax(70px, 1fr))"
      >
        <NewAccountOnly>
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-month',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryMonth"
          />
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-year',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryYear"
          />
        </NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
        <UiSchema.Control
          scope="properties/encCvv"
          forwardProps={{
            autoComplete: 'cc-csc',
            inputMode: 'numeric',
          }}
        />
      </UiSchema.Box>
    </PaymentMethod>
  )
}

function PiqCreditcardRupayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCreditcardRupayDeposit">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/cardHolder"
          forwardProps={{
            autoComplete: 'cc-name',
          }}
        />
        <UiSchema.Control
          scope="properties/encCreditcardNumber"
          forwardProps={{
            autoComplete: 'cc-number',
            inputMode: 'numeric',
            maxLength: '23',
          }}
        />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Box
        display="grid"
        gridGap="inherit"
        gridTemplateColumns="repeat(auto-fit, minmax(70px, 1fr))"
      >
        <NewAccountOnly>
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-month',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryMonth"
          />
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-year',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryYear"
          />
        </NewAccountOnly>
        <UiSchema.Control
          scope="properties/encCvv"
          forwardProps={{
            autoComplete: 'cc-csc',
            inputMode: 'numeric',
          }}
        />
      </UiSchema.Box>
    </PaymentMethod>
  )
}

function PiqCreditcardVisaDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCreditcardVisaDeposit">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/cardHolder"
          forwardProps={{
            autoComplete: 'cc-name',
          }}
        />
        <UiSchema.Control
          scope="properties/encCreditcardNumber"
          forwardProps={{
            autoComplete: 'cc-number',
            inputMode: 'numeric',
            maxLength: '23',
          }}
        />
      </NewAccountOnly>
      <UiSchema.Box
        display="grid"
        gridGap="inherit"
        gridTemplateColumns="repeat(auto-fit, minmax(70px, 1fr))"
      >
        <NewAccountOnly>
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-month',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryMonth"
          />
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-year',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryYear"
          />
        </NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
        <UiSchema.Control
          scope="properties/encCvv"
          forwardProps={{
            autoComplete: 'cc-csc',
            inputMode: 'numeric',
          }}
        />
      </UiSchema.Box>
    </PaymentMethod>
  )
}

function PiqCreditcardWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqCreditcardWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/cardHolder" />
        <UiSchema.Control
          scope="properties/encCreditcardNumber"
          forwardProps={{
            autoComplete: 'cc-number',
            inputMode: 'numeric',
            maxLength: '19',
          }}
        />
        <UiSchema.Box
          display="grid"
          gridGap="inherit"
          gridTemplateColumns="repeat(auto-fit, minmax(70px, 1fr))"
        >
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-month',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryMonth"
          />
          <UiSchema.Control
            forwardProps={{
              autoComplete: 'cc-exp-year',
              inputMode: 'numeric',
              maxLength: '2',
            }}
            scope="properties/expiryYear"
          />
          <UiSchema.Control
            scope="properties/encCvv"
            forwardProps={{
              autoComplete: 'cc-csc',
              inputMode: 'numeric',
              maxLength: '4',
            }}
          />
        </UiSchema.Box>
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqCryptocurrencyDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyDeposit">
      <UiSchema.Control scope="properties/cryptoCurrency" />
      <UiSchema.Component id="cryptoNetworkSelector" />
    </PaymentMethod>
  )
}

function PiqCryptocurrencyWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyWithdrawal">
      <UiSchema.Control scope="properties/cryptoCurrency" />
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletAddress" />
        <UiSchema.Control
          scope="properties/destinationTag"
          effects={[
            UiSchema.effects.hide(),
            UiSchema.effects.show({
              properties: {
                cryptoCurrency: {
                  const: 'XRP',
                },
              },
            }),
          ]}
        />
      </NewAccountOnly>
      <UiSchema.Component id="cryptoNetworkSelector" />
    </PaymentMethod>
  )
}
function PiqCryptocurrencyBitpaceDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyBitpaceDeposit">
      <UiSchema.Control scope="properties/cryptoCurrency" />
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqCryptocurrencyBitpaceWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyBitpaceWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletAddress" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/cryptoCurrency" />
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}
function PiqCryptocurrencyCryptopayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyCryptopayDeposit">
      <UiSchema.Control scope="properties/cryptoCurrency" />
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqCryptocurrencyCryptopayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyCryptopayWithdrawal">
      <UiSchema.Control scope="properties/service" />
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletAddress" />
        <UiSchema.Control
          scope="properties/destinationTag"
          effects={[
            UiSchema.effects.hide(),
            UiSchema.effects.show({
              properties: {
                cryptoCurrency: {
                  const: 'XRP',
                },
              },
            }),
          ]}
        />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/cryptoCurrency" />
    </PaymentMethod>
  )
}
function PiqKluwpDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqKluwpDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqLuxonpayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqLuxonpayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/recipientWalletId" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqEcobanqDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqEcobanqDeposit">
      <UiSchema.Control scope="properties/ecoBanqUserId" />
      <UiSchema.Control scope="properties/ecoBanqPassword" />
    </PaymentMethod>
  )
}

function PiqPayretailersWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPayretailersWithdrawal">
      <UiSchema.Control scope="properties/beneficiaryName" />
      <UiSchema.Control scope="properties/bankName" />
      <UiSchema.Control scope="properties/accountAgencyNumber" />
      <UiSchema.Control scope="properties/accountNumber" />
      <UiSchema.Control scope="properties/documentType" />
      <UiSchema.Control scope="properties/documentNumber" />
      <UiSchema.Control scope="properties/accountType" />
    </PaymentMethod>
  )
}

function PiqPayretailersPixWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPayretailersPixWithdrawal">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/beneficiaryName" />
      <UiSchema.Control scope="properties/bankName" />
      <UiSchema.Control scope="properties/accountAgencyNumber" />
      <UiSchema.Control scope="properties/accountNumber" />
      <UiSchema.Control scope="properties/documentType" />
      <UiSchema.Control scope="properties/documentNumber" />
      <UiSchema.Control scope="properties/accountType" />
      <UiSchema.Control scope="properties/recipientPixKey" />
    </PaymentMethod>
  )
}

function PiqEcobanqWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqEcobanqWithdrawal">
      <UiSchema.Control scope="properties/ecoBanqUserId" />
    </PaymentMethod>
  )
}

function PiqEcopayzDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqEcopayzDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqEcopayzWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqEcopayzWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqIdealDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqIdealDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqInterkassawalletUpiDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqInterkassawalletUpiDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqInterkassawalletUpiWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqInterkassawalletUpiWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqInterkassawalletWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqInterkassawalletWalletDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/walletNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqInteracWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqInteracWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          scope="properties/email"
          forwardProps={{
            autoComplete: 'email',
            inputMode: 'email',
          }}
        />
        <UiSchema.Control
          scope="properties/mobile"
          forwardProps={{
            autoComplete: 'tel',
            inputMode: 'tel',
          }}
        />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqIwalletWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqIwalletWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/destinationAccount" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqMifinityWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqMifinityWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/destinationAccount" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqMuchbetterDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqMuchbetterDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
      <UiSchema.Component id="muchBetterSignUpLink" />
    </PaymentMethod>
  )
}

function PiqMuchbetterWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqMuchbetterWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqNetellerDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqNetellerDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/account" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqNetellerWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqNetellerWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/account" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqPugglepayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqPugglepayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSkrillWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqSkrillWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          forwardProps={{
            inputMode: 'email',
          }}
          scope="properties/email"
        />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqPugglepayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPugglepayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSkrillDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqSkrillDeposit">
      <NewAccountOnly>
        <UiSchema.Control
          forwardProps={{
            inputMode: 'email',
          }}
          scope="properties/email"
        />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSkrillqcoDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqSkrillqcoDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqPay4funWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPay4funWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          forwardProps={{
            inputMode: 'email',
          }}
          scope="properties/email"
        />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqParamountWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqParamountWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/securityQuestion" />
        <UiSchema.Control scope="properties/securityAnswer" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqPaysafecardWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqPaysafecardWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          forwardProps={{
            inputMode: 'email',
          }}
          scope="properties/email"
        />
        <UiSchema.Control scope="properties/firstName" />
        <UiSchema.Control scope="properties/lastName" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSkrillqcoWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqSkrillqcoWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
        <UiSchema.Control scope="properties/accountId" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSticpayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqSticpayWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control
          forwardProps={{
            inputMode: 'email',
          }}
          scope="properties/email"
        />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqSwishDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqSwishDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/swishNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqVenuspointDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqVenuspointDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/username" />
        <UiSchema.Control scope="properties/password" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqVegaDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqVegaDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/username" />
        <UiSchema.Control scope="properties/password" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqVenuspointWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqVenuspointWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/username" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/password" />
    </PaymentMethod>
  )
}

function PiqVegaWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqVegaWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/username" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/password" />
    </PaymentMethod>
  )
}

function PiqWebredirectDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/email" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectSparkasseDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectSparkasseDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectVolksbankenDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectVolksbankenDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectDeutschebankDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectDeutschebankDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectPostbankDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPostbankDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectCommerzbankDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectCommerzbankDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectDeutschekreditbankDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectDeutschekreditbankDeposit">
      <UiSchema.Control scope="properties/service" />
      <UiSchema.Control scope="properties/providerId" />
    </PaymentMethod>
  )
}

function PiqWebredirectOpDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectOpDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectApplepayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectApplepayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGooglepayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGooglepayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectOnlineBankingDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectOnlineBankingDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectEbpDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectEbpDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectTrustlyDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectTrustlyDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectMacropayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectMacropayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectNodapayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectNodapayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectBoletoDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBoletoDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectBoletoPixDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBoletoPixDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectBtDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBtDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqEzeebillBtjpnWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqEzeebillBtjpnWithdrawal">
      <UiSchema.Control scope="properties/service" />
      <NewAccountOnly>
        <UiSchema.Control scope="properties/accountNumber" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/bankCode" />
        <UiSchema.Control scope="properties/branchName" />
        <UiSchema.Control scope="properties/branchCode" />
        <UiSchema.Control scope="properties/beneficiaryName" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectFlykkDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectFlykkDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectFinsupportDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectFinsupportDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqCryptocurrencyFinsupportDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqCryptocurrencyFinsupportDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectJpayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectJpayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectZotapayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectZotapayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectOnlineDebitDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectOnlineDebitDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectDirectPaymentDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectDirectPaymentDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectPixOnlineDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPixOnlineDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectPneDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPneDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectMandatoDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectMandatoDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectMandatoWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectMandatoWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectUpiH5Deposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectUpiH5Deposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectPaytmWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPaytmWalletDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectPhonepeWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPhonepeWalletDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectMobikwikWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectMobikwikWalletDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectAirtelWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectAirtelWalletDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectJioWalletDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectJioWalletDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectCreditCardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectCreditCardDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectUpiWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectUpiWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectLocalBankTransferWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectLocalBankTransferWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqBanklocalLocalBankTransferWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqBanklocalLocalBankTransferWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/bankName" />
        <UiSchema.Control scope="properties/clearingNumber" />
        <UiSchema.Control scope="properties/accountNumber" />
      </NewAccountOnly>
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectOnrampDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectOnrampDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectPagavaDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPagavaDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectBdbanksDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBdbanksDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectRupayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectRupayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectMastercardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectMastercardDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectVisaDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectVisaDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectUpiDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectUpiDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectQuickbitDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectQuickbitDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectSofortDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectSofortDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectSticpayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectSticpayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectSumopayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectSumopayDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectQaicashDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectQaicashDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectPointinoutDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPointinoutDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectQpointsDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectQpointsDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectIbDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectIbDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectZotacardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectZotacardDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectPaytmDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPaytmDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectImpsDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectImpsDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGpayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGpayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectPhonepeDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPhonepeDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectTigerpayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectTigerpayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectBtbDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBtbDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectBtbAutoDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectBtbAutoDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectTigerpayWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectTigerpayWithdrawal">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectQaicashWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectQaicashWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectOnrampWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectOnrampWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectPointinoutWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectPointinoutWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectQpointsWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectQpointsWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/service" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqWebredirectWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/email" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqZimplerDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqZimplerDeposit">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function PiqZimplerWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="PiqZimplerWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/phoneNumber" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

function ProjsSwishDeposit() {
  return (
    <PaymentMethod paymentMethodId="ProjsSwishDeposit">
      <UiSchema.Control scope="properties/bank_id_type" />
      <UiSchema.Control
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              bank_id_type: {const: 'remote_personal_number'},
            },
          }),
        ]}
        forwardProps={{
          inputMode: 'numeric',
          maxLength: '13',
        }}
        scope="properties/personal_number"
      />
      <UiSchema.Control
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              bank_id_type: {
                enum: ['remote_personal_number', 'remote_qr_code'],
              },
            },
          }),
        ]}
        forwardProps={{
          inputMode: 'tel',
          autoComplete: 'tel',
        }}
        scope="properties/phone_number"
      />
    </PaymentMethod>
  )
}

function PwclickPwclickDeposit() {
  return (
    <PaymentMethod paymentMethodId="PwclickPwclickDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function ProjsBankidAuth() {
  return (
    <PaymentMethod paymentMethodId="ProjsBankidAuth">
      <UiSchema.Control scope="properties/bank_id_type" />
      <UiSchema.Control
        forwardProps={{
          inputMode: 'numeric',
          maxLength: '13',
        }}
        effects={[
          UiSchema.effects.hide(),
          UiSchema.effects.show({
            properties: {
              bank_id_type: {const: 'remote_personal_number'},
            },
          }),
        ]}
        scope="properties/personal_number"
      />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2wayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2wayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2wayskrillDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2wayskrillDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waynetellerDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waynetellerDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waygiropayDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waygiropayDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waypaysafecardDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waypaysafecardDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waypaysafecashDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waypaysafecashDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waysofortDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waysofortDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function PiqWebredirectGate2waywebpayzDeposit() {
  return (
    <PaymentMethod paymentMethodId="PiqWebredirectGate2waywebpayzDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function TransferworldTransferworldDeposit() {
  return (
    <PaymentMethod paymentMethodId="TransferworldTransferworldDeposit">
      <UiSchema.Control scope="properties/service" />
    </PaymentMethod>
  )
}

function TransferworldTransferworldWithdrawal() {
  return (
    <PaymentMethod paymentMethodId="TransferworldTransferworldWithdrawal">
      <NewAccountOnly>
        <UiSchema.Control scope="properties/beneficiaryName" />
        <UiSchema.Control scope="properties/iban" />
        <UiSchema.Control scope="properties/swift" />
      </NewAccountOnly>
    </PaymentMethod>
  )
}

export const uiSchema = (
  <UiSchema.Fragment>
    <ProjsBankidAuth />
    <PiqAstropaycardGoDeposit />
    <PiqAstropaycardNbDeposit />
    <PiqAstropaycardPhDeposit />
    <PiqAstropaycardTmDeposit />
    <PiqAstropaycardUiDeposit />
    <PiqBankDeposit />
    <PiqBankBlDeposit />
    <PiqBankIxDeposit />
    <PiqBankJcDeposit />
    <PiqBankBtvoucherDeposit />
    <PiqBankBtvoucherAtmDeposit />
    <PiqBankPpbtvoucherDeposit />
    <PiqBankBriteDeposit />
    <PiqBankNbDeposit />
    <PiqBankUiDeposit />
    <PiqBankDirecta24Deposit />
    <PiqBankInteracDeposit />
    <PiqBankNetBankingDeposit />
    <PiqBankRedeemVoucherDeposit />
    <PiqBankParamountDeposit />
    <PiqBankUpiDeposit />
    <PiqBankVoltDeposit />
    <PiqBankVoltWithdrawal />
    <PiqBankWalletDeposit />
    <PiqCashlibDeposit />
    <PiqBankibanDeposit />
    <PiqBankibanOnlineueberweisenDeposit />
    <PiqBankBriteWithdrawal />
    <PiqCreditcardDeposit />
    <PiqCreditcardMastercardDeposit />
    <PiqCreditcardRupayDeposit />
    <PiqCreditcardVisaDeposit />
    <PiqEcobanqDeposit />
    <PiqEcopayzDeposit />
    <PiqIdealDeposit />
    <PiqBankInovapayDeposit />
    <PiqBankPixDeposit />
    <PiqInovapaybankWithdrawal />
    <PiqInovapaybankPixWithdrawal />
    <PiqInovapaywalletDeposit />
    <PiqInovapaywalletWithdrawal />
    <PiqInterkassawalletUpiDeposit />
    <PiqInterkassawalletUpiWithdrawal />
    <PiqInterkassawalletWalletDeposit />
    <PiqGate2waywalletPaytmWithdrawal />
    <PiqKluwpDeposit />
    <PiqLuxonpayWithdrawal />
    <PiqMifinityWithdrawal />
    <PiqMuchbetterDeposit />
    <PiqNetellerDeposit />
    <PiqPugglepayDeposit />
    <PiqSkrillDeposit />
    <PiqSwishDeposit />
    <PiqSkrillqcoDeposit />
    <PiqVenuspointDeposit />
    <PiqVegaDeposit />
    <PiqWebredirectDeposit />
    <PiqWebredirectOpDeposit />
    <PiqWebredirectApplepayDeposit />
    <PiqWebredirectGooglepayDeposit />
    <PiqWebredirectOnlineBankingDeposit />
    <PiqWebredirectEbpDeposit />
    <PiqWebredirectTrustlyDeposit />
    <PiqWebredirectMacropayDeposit />
    <PiqWebredirectNodapayDeposit />
    <PiqWebredirectBoletoDeposit />
    <PiqWebredirectBoletoPixDeposit />
    <PiqWebredirectBtDeposit />
    <PiqEzeebillBtjpnWithdrawal />
    <PiqWebredirectFlykkDeposit />
    <PiqWebredirectFinsupportDeposit />
    <PiqWebredirectJpayDeposit />
    <PiqWebredirectOnrampDeposit />
    <PiqWebredirectPixOnlineDeposit />
    <PiqWebredirectPagavaDeposit />
    <PiqWebredirectPneDeposit />
    <PiqWebredirectBdbanksDeposit />
    <PiqWebredirectRupayDeposit />
    <PiqWebredirectUpiDeposit />
    <PiqWebredirectMastercardDeposit />
    <PiqWebredirectVisaDeposit />
    <PiqWebredirectQuickbitDeposit />
    <PiqWebredirectSofortDeposit />
    <PiqWebredirectSticpayDeposit />
    <PiqWebredirectSumopayDeposit />
    <PiqWebredirectQaicashDeposit />
    <PiqWebredirectPointinoutDeposit />
    <PiqWebredirectQpointsDeposit />
    <PiqWebredirectIbDeposit />
    <PiqWebredirectZotacardDeposit />
    <PiqWebredirectOnlineDebitDeposit />
    <PiqWebredirectDirectPaymentDeposit />
    <PiqWebredirectUpiH5Deposit />
    <PiqWebredirectPaytmWalletDeposit />
    <PiqWebredirectPhonepeWalletDeposit />
    <PiqWebredirectMobikwikWalletDeposit />
    <PiqWebredirectAirtelWalletDeposit />
    <PiqWebredirectJioWalletDeposit />
    <PiqWebredirectCreditCardDeposit />
    <PiqWebredirectUpiWithdrawal />
    <PiqWebredirectLocalBankTransferWithdrawal />
    <PiqWebredirectOnrampWithdrawal />
    <PiqWebredirectPointinoutWithdrawal />
    <PiqWebredirectQpointsWithdrawal />
    <PiqWebredirectQaicashWithdrawal />
    <PiqWebredirectZotapayDeposit />
    <PiqWebredirectPaytmDeposit />
    <PiqWebredirectImpsDeposit />
    <PiqWebredirectGpayDeposit />
    <PiqWebredirectPhonepeDeposit />
    <PiqWebredirectTigerpayDeposit />
    <PiqWebredirectBtbDeposit />
    <PiqWebredirectBtbAutoDeposit />
    <PiqWebredirectTigerpayWithdrawal />
    <PiqZimplerDeposit />
    <PiqPproDeposit />
    <PiqPproIdealDeposit />
    <ProjsSwishDeposit />
    <PiqAstropaybankWithdrawal />
    <PiqAstropaybankD24indiaWithdrawal />
    <PiqAstropaybankPixWithdrawal />
    <PiqBanklocalWithdrawal />
    <PiqBanklocalLocalBankTransferWithdrawal />
    <PiqBanklocalInpayjpWithdrawal />
    <PiqBanklocalInterkassaWithdrawal />
    <PiqBanklocalSumopayWithdrawal />
    <PiqBanklocalPagavaWithdrawal />
    <PiqBanklocalUpiWithdrawal />
    <PiqBanklocalBtbWithdrawal />
    <PiqBanklocalImpsWithdrawal />
    <PiqBankintlNetbankingWithdrawal />
    <PiqPaymerobankWithdrawal />
    <PiqCreditcardWithdrawal />
    <PiqEcobanqWithdrawal />
    <PiqEcopayzWithdrawal />
    <PiqInteracWithdrawal />
    <PiqBankintlInpayWithdrawal />
    <PiqBankintlNzdWithdrawal />
    <PiqParamountWithdrawal />
    <PiqMuchbetterWithdrawal />
    <PiqNetellerWithdrawal />
    <PiqPay4funWithdrawal />
    <PiqPayretailersWithdrawal />
    <PiqPayretailersPixWithdrawal />
    <PiqPaysafecardWithdrawal />
    <PiqPugglepayWithdrawal />
    <PiqSkrillWithdrawal />
    <PiqSkrillqcoWithdrawal />
    <PiqSticpayWithdrawal />
    <PiqVenuspointWithdrawal />
    <PiqVegaWithdrawal />
    <PiqWebredirectWithdrawal />
    <PiqZimplerWithdrawal />
    <PiqBankdomesticWithdrawal />
    <PiqBankibanWithdrawal />
    <PiqBankibanBriteWithdrawal />
    <PiqBankibanEbpWithdrawal />
    <PiqBankibanNodapayWithdrawal />
    <PiqBankintlWithdrawal />
    <PiqIwalletWithdrawal />
    <PiqCryptocurrencyDeposit />
    <PiqCryptocurrencyWithdrawal />
    <PiqCryptocurrencyBitpaceDeposit />
    <PiqCryptocurrencyBitpaceWithdrawal />
    <PiqCryptocurrencyCryptopayDeposit />
    <PiqCryptocurrencyFinsupportDeposit />
    <PiqCryptocurrencyCryptopayWithdrawal />
    <PwclickPwclickDeposit />
    <PiqBestpaytransferWithdrawal />
    <PiqBanklocalVipwithdrawalJpWithdrawal />
    <PiqMifinityUnionpayDeposit />
    <PiqMifinityKlarnaDeposit />
    <PiqWebredirectSparkasseDeposit />
    <PiqWebredirectVolksbankenDeposit />
    <PiqWebredirectDeutschebankDeposit />
    <PiqWebredirectPostbankDeposit />
    <PiqWebredirectCommerzbankDeposit />
    <PiqWebredirectDeutschekreditbankDeposit />
    <PiqWebredirectGate2wayDeposit />
    <PiqWebredirectGate2wayskrillDeposit />
    <PiqWebredirectGate2waynetellerDeposit />
    <PiqWebredirectGate2waysofortDeposit />
    <PiqWebredirectGate2waywebpayzDeposit />
    <PiqWebredirectGate2waygiropayDeposit />
    <PiqWebredirectGate2waypaysafecardDeposit />
    <PiqWebredirectGate2waypaysafecashDeposit />
    <PiqWebredirectMandatoWithdrawal />
    <PiqWebredirectMandatoDeposit />
    <TransferworldTransferworldDeposit />
    <TransferworldTransferworldWithdrawal />
  </UiSchema.Fragment>
)
